const strReplaceAll = (string = null, searchValue = null, replaceValue = null) => {
  if (!string || !searchValue || !replaceValue || typeof string != 'string' || typeof string != searchValue || typeof string != replaceValue) {
    return ''
  }

  return string.split(searchValue).join(replaceValue)
}

const average = arr => arr.reduce((a, b) => a + b, 0) / arr.length

const breakpoint = key => parseInt(getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-${key}`))

const camelCase = str => str.toLowerCase().replace(/[-_\s.]+(.)?/g, (m, chr) => chr.toUpperCase())

const color = key => getComputedStyle(document.documentElement).getPropertyValue(`--clr-${key}`)

const currentBreakpointKey = strReplaceAll(getComputedStyle(document.documentElement).getPropertyValue(`--breakpoint-current-key`), '"', '').trim()

const environment = document.querySelector('meta[name="environment"]')?.content

const focusableSelector = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const isObject = obj => Object.prototype.toString.call(obj) === '[object Object]'

const isVisible = el => {
  if (!el) return false
  if (!el.offsetWidth && !el.offsetHeight) return false
  if (!el.getClientRects().length) return false
  if (el.display === 'none') return false
  if (el.style.visibility === 'hidden') return false
  return true
}

const language = document.documentElement.lang

const mapboxAccessToken = document.querySelector('meta[name="mapbox"]')?.content

const median = arr => {
  const mid = Math.floor(arr.length / 2)
  const nums = [...arr].sort((a, b) => a - b)

  return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2
}

const parseDOM = (data, type = 'text/html') => new DOMParser().parseFromString(data, type)

const promiseTimeout = (prom, delay) => {
  let timer
  return Promise.race([
    prom,
    new Promise((_r, rej) => timer = setTimeout(rej, delay))
  ]).finally(() => clearTimeout(timer))
}

const serializeDOM = data => new XMLSerializer().serializeToString(data)

const siteCode = document.querySelector('meta[name="current_site"]')?.content

const toggleBodyLock = state => document.body.classList.toggle('is-locked', state)

const viewportOffset = el => {
  const rect = el.getBoundingClientRect()
  const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop

  return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export { average, breakpoint, camelCase, color, currentBreakpointKey, environment, focusableSelector, isObject, isVisible, language, mapboxAccessToken, median, parseDOM, promiseTimeout, serializeDOM, siteCode, toggleBodyLock, viewportOffset }
